
import store from "@/modules/adaptedStorage";

import { defineComponent } from "vue";
import {
  IonPage,
  IonButton,
  IonIcon,
  IonList,
  IonContent,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
  alertController,
} from "@ionic/vue";

import {
  helpBuoyOutline,
  attach,
  imagesOutline,
  alertCircleOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";

import AgentePresenciaJunta from "@/components/AgentePresenciaJunta.vue";
import AgenteJornadaReportarIncidencia from "@/views/AgenteJornadaReportarIncidencia.vue";
import { municipios } from "@/modules/cyanRegions";
import cyanRequest from "@/modules/cyanRequest";
import cyanGetPhoto from '@/modules/cyanGetPhoto';

export default defineComponent({
  name: "AgenteJornada",
  components: {
    IonPage,
    IonButton,
    IonList,
    IonContent,
    IonIcon,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    AgentePresenciaJunta,
  },
  setup() {
    return {
      helpBuoyOutline,
      attach,
      imagesOutline,
      alertCircleOutline,
      checkmarkCircleOutline,
    };
  },
  data() {
    return {};
  },
  computed: {
    baseUrl(): string {
      const p = (this as any).$route.params;

      return "/agente/" + p.ambito + "/" + p.junta + "/";
    },
    seed(): any {
      return (this as any).$route.params.ambito || "SV";
    },
    bootstrap(): any {
      return store.state.bootstrap[this.junta] || {};
    },

    centroJunta(): any {
      const b = this.bootstrap;
      if (!b || !b.centro || !b.junta) return "";
      return "JRV " + b.junta.nombre + ", " + b.centro.nombre;
    },

    junta(): any {
      return (this as any).$route.params.junta || 0;
    },
    municipio(): string {
      if (this.seed in municipios) {
        const m = municipios[(this as any).seed];
        return m.nombre + ", " + m.nombrePadre;
      }
      return "";
    },

    incidencias(): any {
      return (
        store.state.incidencias[this.junta] || {
          ok: false,
          /* eslint-disable @typescript-eslint/camelcase */
          junta_id: this.junta,
          /* eslint-enable @typescript-eslint/camelcase */
          count: 0,
          incidencias: [],
        }
      );
    },
  },
  methods: {
    async nuevaIncidencia() {
      const modal = await modalController.create({
        component: AgenteJornadaReportarIncidencia,
        cssClass: "fullscreen",
        componentProps: {
          junta: this.junta,
          municipio: this.municipio,
        },
      });
      return modal.present();
    },
    abrirDirectorio() {
      const r = this.$route.path.split("/");
      while (r.length > 4) r.pop();
      r[1] = "dirJuntaA";
      this.$router.replace(r.join("/"));
    },

    async marcarResuelta(idIncidencia: any) {
      const confirmPromise = (): Promise<boolean> => {
        return new Promise((resolve) => {
          alertController
            .create({
              message:
                "¿Está seguro de que quiere mandar esta incidencia como resuelta?",
              buttons: [
                {
                  text: "Sí",
                  handler: () => resolve(true),
                },
                {
                  text: "No",
                  role: "cancel",
                  handler: () => resolve(false),
                },
              ],
            })
            .then((alert) => alert.present());
        });
      };

      if (await confirmPromise()) {
        cyanRequest("agente/closeIncidencia/" + idIncidencia, {
          isModal: true,
          isPost: true,
          needsAuth: true,
        }).then(function (d) {
          if (d.ok) {
            store.commit("storeIncidencia", d.incidencia);
          }
        });
      }
    },

    async enviarFoto(idIncidencia: any) {
      const image = await cyanGetPhoto();

      if (image === false) {
        return;
      }

      cyanRequest("agente/addMedioIncidencia/" + idIncidencia, {
        isPost: true,
        files: { fichero: image },
        isModal: true,
        needsAuth: true,
      }).then(function (d) {
        if (d.ok) {
          store.commit("storeIncidencia", d.incidencia);
        }
      });
    },
    verFotos(incidencia: any) {
      const result = [];
      if (incidencia.medios.length) {
        let counter = 1;
        for (const i of incidencia.medios) {
          result.push({
            src: i.url,
            title: "Imagen " + counter++ + " de " + incidencia.medios.length,
          });
        }
      }

      if (result.length) {
        store.commit("setLightboxImages", result);
        store.commit("setLightboxVisible", true);
      }
    },
  },
});
